/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Component, ChangeDetectionStrategy, OnDestroy, ApplicationRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditionModeService } from '../../../../services/edition-mode.service';
import { AudioComponent } from '../../dialogs/add-modules-base/modulos/audio/audio.component';
import { DestacadoComponent } from '../../dialogs/add-modules-base/modulos/destacado/destacado.component';
import { DocumentoComponent } from '../../dialogs/add-modules-base/modulos/documento/documento.component';
import { EnlaceComponent } from '../../dialogs/add-modules-base/modulos/enlace/enlace.component';
import { ImagenComponent } from '../../dialogs/add-modules-base/modulos/imagen/imagen.component';
import { SeccionComponent } from '../../dialogs/add-modules-base/modulos/seccion/seccion.component';
import { TablaComponent } from '../../dialogs/add-modules-base/modulos/tabla/tabla.component';
import { TextoComponent } from '../../dialogs/add-modules-base/modulos/texto/texto.component';
import { VideoComponent } from '../../dialogs/add-modules-base/modulos/video/video.component';
import { EditarRecursoComponent } from '../../dialogs/editar-recurso/editar-recurso.component';
import { TemasManagementService } from '../../../../services/data/temas/temas-management.service';
import { JumboTemaComponent } from '../../dialogs/add-modules-base/modulos/jumbo-tema/jumbo-tema.component';
import { MoverComponent } from '../../dialogs/mover/mover.component';
import { MateriaManagementService } from '../../../../services/data/materia/materia-management.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GtagService } from '../../../../services/gtag/gtag.service';
import { EditRecursoPlantillaComponent } from '../../dialogs/edit-recurso-plantilla/edit-recurso-plantilla.component';
import { environment } from '../../../../../environments/environment';
import { LtiManagementService } from '../../../../services/data/lti/lti-management.service';
import { AuthManagementService } from '../../../../core/auth/auth-management.service';
import { LocalCacheService } from '../../../../core/load-cache/load-cache.service';

@Component({
  selector: 'aula-planeta-edition-bar',
  templateUrl: './edition-bar.component.html',
  styleUrls: ['./edition-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditionBarComponent implements OnDestroy {

  dialogComponents = {
    seccion1: SeccionComponent,
    seccion2: SeccionComponent,
    seccion3: SeccionComponent,
    seccion4: SeccionComponent,
    audio: AudioComponent,
    enlace: EnlaceComponent,
    destacado: DestacadoComponent,
    documento: DocumentoComponent,
    imagen: ImagenComponent,
    tabla: TablaComponent,
    texto: TextoComponent,
    video: VideoComponent,
    recurso: EditarRecursoComponent,
    'jumbo-tema': JumboTemaComponent,
    'recurso-col': EditarRecursoComponent,
    'seccion1-recurso': SeccionComponent
  }


  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public applicationRef: ApplicationRef,
    public dialog: MatDialog,
    public editionModeService: EditionModeService,
    public authManagementService: AuthManagementService,
    public temasManagementService: TemasManagementService,
    public ltiManagementService: LtiManagementService,
    private materiaManagementService: MateriaManagementService,
    private gtagService: GtagService,
    private localCacheService: LocalCacheService
  ) { }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  switchingActions(id, event?: PointerEvent) {
    this.temasManagementService.saveScrollPosition();
    const infoMenu = {
      event: 'select_menu_item',
      selectMenuItem: {
        menuItemName: id,
        menuId: 'edition-bar',
        pageId: event?.view.location.hash || undefined
      }
    };
    this.gtagService.event(infoMenu);
    this.editionModeService.action = id;
    switch (id) {
      case 'edit':
        if (this.editionModeService.type === 'texto' || (this.editionModeService.type === 'destacado')) {
          this.editionModeService.parentInjector._lView[8].isDisabled = true;
          this.editionModeService.dialogOpen = true;
        } else {
          if ((this.editionModeService.moduloSeleccionado?.comunes?.tipoModulo?.valor === 'recurso' ||
            this.editionModeService.type === 'tabla' ||
            this.editionModeService.type === 'audio' ||
            this.editionModeService.type === 'video' ||
            this.editionModeService.type === 'documento' ||
            this.editionModeService.type === 'imagen'
          ) && !this.editionModeService.moduloSeleccionado?.autor) {
            this.temasManagementService.duplicarModulo(this.editionModeService.moduloSeleccionado?.id).pipe(
              take(1),
              takeUntil(this.destroy$))
              .subscribe(nuevoModulo => {
                if (nuevoModulo) {
                  this.temasManagementService.tema.modulosOrganizacion =
                    this.editionModeService.modificarModulo(this.temasManagementService.tema.modulosOrganizacion, null, nuevoModulo.propiedades, this.editionModeService.moduloSeleccionado?.id, nuevoModulo.id, true);
                  this.editionModeService.moduloSeleccionado = nuevoModulo;
                  this.openDialog(this.editionModeService.type, this.editionModeService.moduloSeleccionado);
                }

              });
          } else {
            this.openDialog(this.editionModeService.type, this.editionModeService.moduloSeleccionado);
          }
        }
        break;
      case 'mover':
        this.mover();
        break;
      case 'delete':
        if (this.editionModeService.type === 'seccion1') {
          this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.deleteSeccion1(this.temasManagementService.tema.modulosOrganizacion);
        } else {
          this.temasManagementService.tema.modulosOrganizacion =
            this.editionModeService.deleteModulo(this.temasManagementService.tema.modulosOrganizacion, null, this.editionModeService.parent.id);
        }
        this.refresh();
        break;
      case 'duplicate':

        if (this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.valor === 'recurso' ||
          this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.idValor === 'tabla' ||
          this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.idValor === 'audio' ||
          this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.idValor === 'video' ||
          this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.idValor === 'documento' ||
          this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.idValor === 'imagen'
        ) {
          if (this.editionModeService.moduloSeleccionado?.propiedades?.tipoActividad?.valor?.editor) {

            this.temasManagementService.duplicarModuloConEditorReferenciado(this.editionModeService.moduloSeleccionado?.id).pipe(
              take(1),
              takeUntil(this.destroy$))
              .subscribe(moduloDuplicado => {

                // let player = moduloDuplicado.propiedades?.tipoActividad?.valor?.player;
                // player = player.split('fs1://')[1];
                // player = player.split('.zip')[0];
                // let url = moduloDuplicado.propiedades?.tipoActividad?.valor?.editor;
                // const idModulo = moduloDuplicado.id;
                // url = url.split('fs1://')[1];
                // url = `${environment.rootContentNoCDN}/Contenido/` + url;
                // url = url.split('.zip')[0];
                // url = url + `/Editor.html?data_path=/Contenido/Modulos/${idModulo}/data&plantilla=1&out_url=${environment.api}/plantilla/actualizar/${idModulo}/crear&preview_path=/Contenido/${player}`;

                // const dialogRef = this.dialog.open(EditRecursoPlantillaComponent, {
                //   data: {
                //     url,
                //     idModulo: idModulo,
                //     color: 'standard'
                //   }
                // });
                // dialogRef.afterClosed().pipe(take(1), takeUntil(this.destroy$)).subscribe(nuevoModulo => {
                if (moduloDuplicado) {
                  // idTipoComponenteModulo = idTipoComponenteModulo.slice(0, -1) === 'seccion' ? 'seccion' : idTipoComponenteModulo;
                  moduloDuplicado.comunes.tipoModulo.idValor = 7;
                  // hija = this.editionModeService.type === 'seccion2' || this.editionModeService.type === 'seccion3';
                  // nuevoModulo.comunes.tipoModulo.valor = 'recurso';
                  // nuevoModulo.id = respuesta.id;

                  moduloDuplicado.modulosOrganizacion = [];
                  moduloDuplicado.propiedades.visible = 'Alumnos';
                  moduloDuplicado.temporal = true;
                  moduloDuplicado.autor = {
                    id: this.authManagementService.currentUser.persona.id,
                    nombre: `${this.authManagementService.currentUser.persona.nombre} ${this.authManagementService.currentUser.persona.apellidoUno} ${this.authManagementService.currentUser.persona.apellidoDos}`
                  };
                  this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.addNewModulo(
                    this.temasManagementService.tema.modulosOrganizacion, null, moduloDuplicado, this.editionModeService.parent.id, 'down', false);
                  this.refresh();
                }
                // });
              });
          } else {
            this.temasManagementService.duplicarModulo(this.editionModeService.moduloSeleccionado?.id).pipe(
              take(1),
              takeUntil(this.destroy$))
              .subscribe(nuevoModulo => {
                if (nuevoModulo) {
                  // this.editionModeService.moduloSeleccionado = nuevoModulo;
                  this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.addNewModulo(this.temasManagementService.tema.modulosOrganizacion, null, nuevoModulo, this.editionModeService.parent.id, 'down', null);
                  this.refresh();
                }

              });

          }
        } else {

          const moduloDuplicado = JSON.parse(JSON.stringify(this.editionModeService.moduloSeleccionado));
          moduloDuplicado.id = this.editionModeService.generateUUID();
          moduloDuplicado.temporal = true;
          moduloDuplicado.autor = {
            id: this.authManagementService.currentUser.persona.id,
            nombre: `${this.authManagementService.currentUser.persona.nombre} ${this.authManagementService.currentUser.persona.apellidoUno} ${this.authManagementService.currentUser.persona.apellidoDos}`
          };
          if (moduloDuplicado.comunes.tipoModulo.idValor === 1) {
            moduloDuplicado.clave.smartlinkId = this.editionModeService.generateUUID();
          }
          if (this.editionModeService.type === 'seccion1') {
            this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.addNewSeccion1(this.temasManagementService.tema.modulosOrganizacion, moduloDuplicado, 'down');
          } else {
            this.editionModeService.type = this.editionModeService.type.slice(0, -1) === 'seccion' ? 'seccion' : this.editionModeService.type;
            this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.addNewModulo(this.temasManagementService.tema.modulosOrganizacion, null, moduloDuplicado, this.editionModeService.parent.id, 'down', null);
          }
          this.refresh();
        }

        break;
      case 'Profesores':
        this.modificarModulo({ visible: 'Profesores' });
        this.editionModeService.moduloSeleccionado = this.editionModeService.cambiarVisibilidadHijos(this.editionModeService.moduloSeleccionado, 'Profesores');
        this.temasManagementService.tema.modulosOrganizacion =
          this.editionModeService.replaceModulo(this.temasManagementService.tema.modulosOrganizacion, null, this.editionModeService.parent.id);
        break;
      case 'Alumnos':
        this.modificarModulo({ visible: 'Alumnos' });
        this.editionModeService.moduloSeleccionado = this.editionModeService.cambiarVisibilidadHijos(this.editionModeService.moduloSeleccionado, 'Alumnos');
        this.temasManagementService.tema.modulosOrganizacion =
          this.editionModeService.replaceModulo(this.temasManagementService.tema.modulosOrganizacion, null, this.editionModeService.parent.id);
        break;
      case 'Oculto':
        this.modificarModulo({ visible: 'Oculto' });
        this.editionModeService.moduloSeleccionado = this.editionModeService.cambiarVisibilidadHijos(this.editionModeService.moduloSeleccionado, 'Oculto');
        this.temasManagementService.tema.modulosOrganizacion =
          this.editionModeService.replaceModulo(this.temasManagementService.tema.modulosOrganizacion, null, this.editionModeService.parent.id);
        break;
      case 'up':
        if (this.editionModeService.type === 'seccion1') {
          this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.moverSeccion1(this.temasManagementService.tema.modulosOrganizacion, 'up');
        } else {
          this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.moverModuloBuscarElAnterior(this.temasManagementService.tema.modulosOrganizacion, null, this.editionModeService.parent.id, 'up', this.temasManagementService.modulosList);
        }
        this.refresh();
        break;
      case 'down':
        if (this.editionModeService.type === 'seccion1') {
          this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.moverSeccion1(this.temasManagementService.tema.modulosOrganizacion, 'down');
        } else {
          this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.moverModuloBuscarElAnterior(this.temasManagementService.tema.modulosOrganizacion, null, this.editionModeService.parent.id, 'down', this.temasManagementService.modulosList);
        }
        this.refresh();
        break;
    }
  }

  openDialog(idTipoComponenteModulo, modulo) {
    this.editionModeService.dialogOpen = true;
    document.body.classList.add('cdk-global-scrollblock');
    const dialogRef = this.dialog.open(this.dialogComponents[idTipoComponenteModulo], {
      data: modulo,
      restoreFocus: true
    });
    dialogRef.afterClosed().pipe(take(1), takeUntil(this.destroy$)).subscribe(propiedades => {
      document.body.classList.remove('cdk-global-scrollblock');
      this.editionModeService.dialogOpen = false;
      if (propiedades) {
        // es una modificacion del jumbo
        if (propiedades.isJumbo) {
          this.modificarUnidadTema(propiedades);
        } else {
          let newId = null;
          let updateState = false;
          if (
            this.editionModeService.type === 'seccion' ||
            this.editionModeService.type === 'seccion1' ||
            this.editionModeService.type === 'seccion2' ||
            this.editionModeService.type === 'seccion3' ||
            this.editionModeService.type === 'seccion4' ||
            this.editionModeService.type === 'enlace' ||
            this.editionModeService.type === 'destacado' ||
            this.editionModeService.type === 'ejemploResuelto' ||
            this.editionModeService.type === 'aprendizaje' ||
            this.editionModeService.type === 'definicion' ||
            this.editionModeService.type === 'recursoAprendizaje' ||
            this.editionModeService.type === 'tabla' ||
            this.editionModeService.type === 'audio' ||
            this.editionModeService.type === 'video' ||
            this.editionModeService.type === 'documento' ||
            this.editionModeService.type === 'imagen'
          ) {

            switch (this.editionModeService.type) {
              case 'tabla':

                break;
              case 'audio':

                break;
              case 'video':

                break;
              case 'documento':

                break;
              case 'imagen':
                if (!propiedades.imagen) {
                  delete propiedades.imagen;
                }
                if (!propiedades.imagen_previa) {
                  delete propiedades.imagen_previa;
                }
                break;

              default:
                break;
            }



            const regex = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/g;
            const isPersonalizado = modulo?.id?.match(regex);

            if (!modulo.autor || !isPersonalizado) {
              newId = this.editionModeService.generateUUID();
              modulo.id = newId;
            }

          } else {
            updateState = true;
          }

          this.modificarModulo(propiedades, newId, updateState);
        }
      }
    });
  }

  mover() {
    const isModuloRecurso = this.editionModeService.type.includes('recurso');
    const isModuloSeccion = this.editionModeService.type.includes('seccion');
    const cuantosNivelesMeCuelgan = this.calcularProfundidad(this.editionModeService.moduloSeleccionado?.modulosOrganizacion);
    document.body.classList.add('cdk-global-scrollblock');
    const dialogRef = this.dialog.open(MoverComponent, {
      data: {
        titulo: isModuloRecurso ? 'dialogs.move-recurso.title' : 'dialogs.move-seccion.title',
        subtitulo: isModuloRecurso ? 'dialogs.move-recurso.subtitle' : 'dialogs.move-seccion.subtitle',
        currentLocationId: this.materiaManagementService.materia.id,
        // cursos: this.materiaManagementService.getLocations(),
        cursos: this.materiaManagementService.getLocations(),
        isTemas: true,
        isTemasSeleccionable: isModuloSeccion,
        isSecciones: true,
        profundidad: cuantosNivelesMeCuelgan + 1,
        isSeccionIntoSeccion: true
      },
      restoreFocus: true
    });
    dialogRef.afterClosed().pipe(
      take(1),
      takeUntil(this.destroy$))
      .subscribe((result: any) => {
        document.body.classList.remove('cdk-global-scrollblock');
        if (result) {

          if (this.temasManagementService.tema.id === result.location) {
            if (this.editionModeService.type === 'seccion1') {
              this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.deleteSeccion1(this.temasManagementService.tema.modulosOrganizacion);
            } else {
              this.temasManagementService.tema.modulosOrganizacion =
                this.editionModeService.deleteModulo(this.temasManagementService.tema.modulosOrganizacion, null, this.editionModeService.parent.id);
            }
            this.temasManagementService.tema.modulosOrganizacion = this.editionModeService.addNewModulo(this.temasManagementService.tema.modulosOrganizacion, null, this.editionModeService.moduloSeleccionado, result.seccion.id, 'down', null);
            this.refresh();
            this.localCacheService.expire('MATERIADATASERVICE-GETMISMATERIAS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
            this.localCacheService.expire('MATERIADATASERVICE-GETMISLIBROS').pipe(take(1), takeUntil(this.destroy$)).subscribe();
          } else {

            const payload = {
              origenTemaId: this.temasManagementService.tema.id,
              origenModuloOrganizacionId: this.editionModeService.moduloSeleccionado?.id,
              otroTemaId: result.location,
              otroTemaSeccionNivel1: result.seccion ? result.seccion.indice.seccion : 0,
              otroTemaSeccionNivel2: result.seccion ? result.seccion.indice.subseccion : 0,
              otroTemaSeccionNivel3: result.seccion ? result.seccion.indice.subsubseccion : 0
            };
            this.temasManagementService.moverModulo(payload).pipe(
              take(1),
              takeUntil(this.destroy$))
              .subscribe(() => {
                this.switchingActions('delete');
                this.refresh();
                this.localCacheService.expire(`TEMASDATASERVICE-GETTEMA-${this.temasManagementService.tema.id}`)
                  .pipe(take(1), takeUntil(this.destroy$)).subscribe();
                this.localCacheService.expire(`TEMASDATASERVICE-GETTEMA-${result.location}`)
                  .pipe(take(1), takeUntil(this.destroy$)).subscribe();
                // this.materiaManagementService.getMateria(this.materiaManagementService.materia.id);
              });
          }
        }
      });
  }

  modificarUnidadTema(propiedades) {
    this.temasManagementService.tema.unidad = { ...this.temasManagementService.tema.unidad, ...propiedades };
    this.refresh();
  }

  modificarModulo(propiedades, newId?, updateState?) {
    this.temasManagementService.tema.modulosOrganizacion =
      this.editionModeService.modificarModulo(this.temasManagementService.tema.modulosOrganizacion, null, propiedades, this.editionModeService.moduloSeleccionado?.id, newId, updateState);
    this.refresh();
  }

  refresh() {
    this.editionModeService.recalculoIndicesOrden(this.temasManagementService.tema);
    this.temasManagementService.tema = { ...this.temasManagementService.tema } as any;
  }

  canDuplicar() {
    return (this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.valor === 'texto' ||
      this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.valor === 'recurso' ||
      this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.valor === 'seccion' ||
      this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.valor === 'seccion1' ||
      this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.valor === 'seccion2' ||
      this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.valor === 'seccion3' ||
      this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.valor === 'seccion4' ||
      this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.valor === 'enlace' ||
      this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.valor === 'destacado' ||
      this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.valor === 'ejemploResuelto' ||
      this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.valor === 'aprendizaje' ||
      this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.valor === 'definicion' ||
      this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.valor === 'recursoAprendizaje' ||
      this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.valor === 'tabla' ||
      this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.valor === 'audio' ||
      this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.valor === 'video' ||
      this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.valor === 'documento' ||
      this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.valor === 'imagen'

    );
  }

  canDuplicarEditar() {
    return (this.editionModeService.moduloSeleccionado?.comunes.tipoModulo.valor === 'recurso' && this.editionModeService.moduloSeleccionado?.propiedades?.tipoActividad?.valor?.editor);
  }

  private calcularProfundidad(modulos, nivel = 0) {
    let maxProfundidad = nivel; // Profundidad actual

    // Iterar a través de cada módulo
    modulos.forEach(modulo => {
      if (modulo.comunes.tipoModulo.valor === 'seccion') { // Verificar si es una sección
        const profundidad = this.calcularProfundidad(modulo.modulosOrganizacion, nivel + 1);
        // Actualizar la profundidad máxima si encontramos una más profunda
        if (profundidad > maxProfundidad) {
          maxProfundidad = profundidad;
        }
      }
    });

    return maxProfundidad; // Devolver la profundidad máxima
  }
}
