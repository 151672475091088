import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';


interface Scripts {
    name: string;
    src: string;
    initial: boolean,
    dataDomainScript?: string;
    
}

declare global {
    interface Window {
        dataLayer: any;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        OptanonWrapper: any;
    }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const scriptStore: Scripts[] = [
    {
        initial: true,
        name: 'gtagmanager',
        src: `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalytics}`
    },
    // {
    //     initial: false,
    //     name: 'cookie-pro-consent', src: 'https://cookie-cdn.cookiepro.com/consent/013f923e-a587-4d43-940f-f0b443aed4c4/OtAutoBlock.js'
    // },
    // {
    //     initial: false ,
    //     name: 'cookie-pro-template',
    //     src: 'https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js',
    //     dataDomainScript: '013f923e-a587-4d43-940f-f0b443aed4c4'
    // },
    

];

declare let document: any;

@Injectable({ providedIn: 'root' })
export class ScriptService {

    private scripts: any = {};

    constructor() {
        scriptStore.forEach((script: any) => {
            this.scripts[script.name] = {
                loaded: false,
                src: script.src,
                dataDomainScript: script.dataDomainScript,
                
            };
        });
    }

    init() {
        
        this.load();  

                                
        window.dataLayer = window.dataLayer || [];
        // eslint-disable-next-line quote-props, @typescript-eslint/naming-convention
        window.dataLayer.push('config', environment.googleAnalytics, { 'send_page_view': false });
        // Google Tag Manager
        (function (w, d, s, l, i) {
            w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js', config: i });
            // eslint-disable-next-line eqeqeq
            const f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', environment.googleAnalytics);
        // Userguiding
        // (function (g, u, i, d, e, s) {
        //     g[e] = g[e] || [];
        //     const f = u.getElementsByTagName(i)[0];
        //     const k = u.createElement(i);
        //     k.async = true;
        //     k.src = 'https://static.userguiding.com/media/user-guiding-' + s + '-embedded.js';
        //     f.parentNode.insertBefore(k, f);
        //     if (g[d]) return;
        //     const ug = g[d] = { q: [] } as any;
        //     ug.c = function (n) { return function () { ug.q.push([n, arguments]) }; };
        //     const m = ['previewGuide', 'finishPreview', 'track', 'identify', 'hideChecklist', 'launchChecklist'];
        //     for (let j = 0; j < m.length; j += 1) { ug[m[j]] = ug.c(m[j]); }
        // })(window, document, 'script', 'userGuiding', 'userGuidingLayer', '516726976ID');
        this.initMutationObserver();
    }


    initMutationObserver() {
        const observer = new MutationObserver((mutationsList) => {
          mutationsList.forEach((mutation) => {
            mutation.addedNodes.forEach((node: Node) => {
              if (node instanceof HTMLImageElement) {
                
                node.setAttribute('data-ot-ignore', 'true');
              }
            });
          });
        });
    
        // Configurar el observer para observar cambios en el body del DOM
        observer.observe(document.body, { childList: true, subtree: true });
      }

   load() {
        const promises: any[] = [];
        scriptStore.forEach((script) => {
            if (script.initial ) {
                promises.push(this.loadScript(script.name));
            }
        }
        );
        return Promise.all(promises);
    }

    loadScript(name: string) {
        return new Promise((resolve, reject) => {
            // resolve if already loaded
            if (this.scripts[name].loaded) {
                resolve({ script: name, loaded: true, status: 'Already Loaded' });
            }
            else {
                // load script
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = this.scripts[name].src;
                script.async = true;
              
                
                if (script.readyState) {  
                    script.onreadystatechange = () => {
                        if (script.readyState === 'loaded' || script.readyState === 'complete') {
                            script.onreadystatechange = null;
                            this.scripts[name].loaded = true;
                            
                            resolve({ script: name, loaded: true, status: 'Loaded' });
                        }
                    };

                   
                } else {  // Others
                    script.onload = () => {
                        this.scripts[name].loaded = true;
                        if (this.scripts[name].name === 'cookie-pro-template') {
                            const _window = window as any;
                            if (_window.OptanonWrapper) {
                                _window.OptanonWrapper();
                            }
                        }
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                }
                script.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });

                if (this.scripts[name].dataDomainScript) {
                    script.setAttribute('data-domain-script', this.scripts[name].dataDomainScript);
                    script.setAttribute('charset', 'UTF-8');
                }    
                document.getElementsByTagName('head')[0].appendChild(script);
            }
        });
    }

}